//React
import React from 'react'

//Packages
import Helmet from 'react-helmet'

//Components
import Layout from '../../components/layout'
import NavSubUsecases from '../../components/nav/navSubUsecases'
import FeatureQuad from '../../components/marketing/featureQuad'
import AlternatingCallout from '../../components/marketing/alternatingCallout'
import BenefitsBoomerang from '../../components/marketing/benefitsBoomerang'

const useCaseData = [
  {
    title: 'Drive revenue for your restaurant',
    titleWeight: 'medium',
    titleColor: 'white-1',
    description:
      'More than ever, your restaurant needs to stay top-of-mind for your customers. With visual social marketing, you can drive immediate action from your customers with offers, promotions, and more.',
    descriptionColor: 'white-3',
    image: 'usecaseRestaurant',
    iconShow: true,
    iconContext: 'restaurant',
    // iconColor: 'white',
    iconfill: 'transparent',
    iconcolor: 'white',
    // lineClass: 'green-1',
    // bgcolor: 'dkgreen-8',
    // border: 'border-top-2-dkgreen-1',
    bggradient: 'overcast',
    expandcol: 5,
  },
]
const featureData = {
  title: 'Keep customers coming to your restaurant',
  titlecolor: 'black-4',
  mode: 'triovertical',
  bgcolor: 'grayblue-6',
  rowdata: [
    {
      // rowbgcolor: "green",
      rowdata: [
        {
          title: 'Promotions and specials',
          titlecolor: 'blue-1',
          desccolor: 'black-1',
          colbgcolor: 'white-1',
          colbordercolor: 'blue-1',
          iconname: 'tag',
          iconcolor: 'blue',
          path: '/platform/offers-promotions/',
          description:
            'Drive new customers and repeat visits by rapidly creating image-rich offers, specials, and promotions via drag-and-drop',
        },
        {
          title: 'Positive restaurant reviews',
          titlecolor: 'green-1',
          desccolor: 'black-1',
          colbgcolor: 'white-1',
          colbordercolor: 'green-1',
          iconname: 'megaphone',
          iconcolor: 'green',
          path: '/platform/review-management/',
          description:
            'Encourage your customers to leave positive reviews through easily customizable review templates that fit your brand',
        },

        {
          title: 'Content marketing',
          titlecolor: 'orange-1',
          desccolor: 'black-1',
          colbgcolor: 'white-1',
          colbordercolor: 'orange-1',
          iconname: 'shield',
          iconcolor: 'orange',
          path: '/platform/safety-compliance/',
          description:
            'Showcase your brand and drive engagement with content marketing templates, including lifestyle and behind-the-scenes content.',
        },
      ],
    },
  ],
}
const About = (props) => (
  <Layout>
    <Helmet
      title="Storylava - Restaurants - Visual Content Marketing"
      meta={[
        {
          name: 'description',
          content:
            'Learn about how Storylava can help you drive more revenue at your restaurant with visual social marketing',
        },
      ]}
    />

    <NavSubUsecases />

    <div class="container container-page container-about-sub">
      <div class="row">
        <div class="col-xl-10 d-flex flex-column justify-content-center text-center text-lg-left">
          <h1 class="display-2 font-weight-medium sky-1">
            Keep customers coming back to your restaurant
          </h1>
          <p class="display-4">
            Stay top-of-mind with your dine-in, take-out and catering customers
            with offers, promotions, review requests, and more.
          </p>
        </div>
      </div>
    </div>

    <AlternatingCallout featureData={useCaseData} />

    {/* SELECTED FEATURES */}
    <FeatureQuad featureData={featureData} />
    {/* END SELECTED FEATURES */}
  </Layout>
)

export default About
